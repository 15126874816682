<template>
    <section class="sect-admin-tribus pl-4">
        <div class="row">
            <div class="col-12 col-lg-9">
                <div class="row mb-4">
                    <div class="col-auto my-auto">
                        <p class="f-28 font-bold-italic">TRIBUS</p>
                    </div>
                    <div class="col-4 ml-lg-auto my-auto">
                        <el-input v-model="search" class="w-100" placeholder="Buscar" prefix-icon="el-icon-search" />
                    </div>
                    <div class="col-auto my-auto">
                        <div class="btn-general font-medium-italic px-3 f-300 w-178px f-18 h-32px" @click="createTribe">
                            CREAR TRIBU
                        </div>
                    </div>
                </div>
                <!--  -->
                <div v-for="(item, index) in listaTribus" :key="index" class="row mx-0 mt-4">
                    <div class="col-auto px-2 inclinacion" :style="`background-color: ${item.color}`" />
                    <div class="col py-2 inclinacion bg-fondo ml-2 py-4">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <div class="inclinacion w-30px h-27px text-white text-center font-bold-italic f-20" :style="`background-color: ${item.color}`">
                                    {{ index+1 }}°
                                </div>
                            </div>
                            <div class="col-auto col-lg-4 my-auto cr-pointer" @click="verDetalleTribu(item, index)">
                                <div class="d-middle">
                                    <img :src="item.imagen" alt="" class="wf-52px bg-inactive h-52px bg-70" />
                                    <div class="ml-3">
                                        <p class="f-20 font-bold-italic">{{ item.nombre }}</p>
                                        <p class="f-17 text-red font-black-italic"> {{ item.total_puntos }} pts.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto my-auto">
                                <div class="d-middle text-grey-light fk-regular">
                                    <i class="icon-account-circle-outline" />
                                    <p class="f-17 ml-1"> {{ item.participantes }} Participantes</p>
                                </div>
                            </div>
                            <div class="col-auto my-auto d-flex text-general ml-auto">
                                <el-switch v-model="item.estado" active-color="#29D884" inactive-color="#DFE1E6" :active-value="1" :inactive-value="0" @change="cambiarEstadoTribu(item)"/>
                            </div>
                            <div class="col-auto my-auto d-middle">
                                <!-- <el-tooltip effect="light" content="Agregar participante" placement="bottom">
                                    <div class="btn-general wf-47px mx-2" @click="openModalAddMember">
                                        <i class="icon-plus" />
                                    </div>
                                </el-tooltip> -->
                                <el-tooltip effect="light" content="Editar tribu" placement="bottom">
                                    <div class="btn-grey mx-2 wf-47px" @click="editTribe(item)">
                                        <i class="icon-pencil-outline f-15" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip effect="light" content="Eliminar tribu" placement="bottom">
                                    <div class="btn-grey mx-2 wf-47px" @click="openModalDeleteTribe(item.id)">
                                        <i class="icon-icon-trash-outline f-15" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="row mx-0 a-center" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-crear-tribu @listarTribus="listarTribus" ref="refModalCrearTribu" />
        <modal-agregar-participante ref="refModalAgregarParticipante" />
        <modal-eliminar-tribu @eliminarTribu="eliminarTribu" ref="refModalEliminarTribu" />
    </section>
</template>

<script>
import modalCrearTribu from './partials/modalCrearTribu.vue'
import modalAgregarParticipante from './partials/modalAgregarParticipante.vue'
import modalEliminarTribu from './partials/modalEliminarTribu.vue'
import Tribus from '~/services/tribus/tribus'
export default {
    components: {
        modalCrearTribu,
        modalAgregarParticipante,
        modalEliminarTribu,
    },
    data() {
        return {
            rutas: [
                { titulo: 'TRIBUS', ruta: 'asd' },
                { titulo: 'PREMIOS', ruta: 'asd' },
            ],
            value2: false,
            search: '',
            tribus: [],
            idTribu:null
        }
    },
    computed:{
        listaTribus(){
            if(this.search !== null && this.search !== ''){
                return this.tribus.filter(el => el.nombre.toLowerCase().includes(this.search.toLowerCase()))

            }else{
                return this.tribus
            }
        },
    },
    mounted() {
        this.listarTribus()
    },
    methods: {
        openModalAddMember() {
            this.$refs.refModalAgregarParticipante.toggle()
        },
        openModalDeleteTribe(id) {
            this.idTribu = id
            this.$refs.refModalEliminarTribu.toggle(id)
        },
        createTribe() {
            this.$refs.refModalCrearTribu.toggle()
        },
        editTribe(item) {
            this.$refs.refModalCrearTribu.toggle(item)
        },
        async listarTribus(){
            try {
                const { data } = await Tribus.listarTribus();
                this.tribus = data?.data ?? []
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al listar tribus'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async cambiarEstadoTribu(item){
            try {
                await Tribus.cambiarEstadoTribu(item.id);
                this.notificacion('Mensaje', 'Actualizado correctamente', 'success')
            } catch (error) {
                this.listarTribus()
                const mensaje = error?.response?.data?.error ?? 'error al cambiar estado'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async eliminarTribu(){
            try {
                const { data } = await Tribus.eliminarTribu(this.idTribu);
                if (data?.exito) {
                    this.notificacion('Mensaje', "eliminado correctamente", 'success')
                    this.tribus = this.tribus.filter(el => el.id != this.idTribu)
                }
                this.idTribu = null
            } catch (error) {
                const mensaje = error?.response?.data?.mensaje ?? 'error al eliminar tribus'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        verDetalleTribu(item, index){
            this.$router.push({name: 'admin.tribus.detalle', params:{id_tribu:item.id, posicion:index+1}});
        }
    }
}
</script>

<style lang="scss" scoped>
.sect-admin-tribus{
	.text-grey-light{
		color: #637381;
	}
}
</style>